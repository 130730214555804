export default class BlockStrateFull {
    constructor() {
        if ($('.wp-block-strate-full').length > 0) {
            import(
                '@blocks/strate-full/scripts/services/bg-size.service' /* webpackChunkName: "scripts/services/bg-size.service" */
            ).then(({ default: BgSizeService }) => {
                new BgSizeService()
            })
        }
    }
}

new BlockStrateFull()
